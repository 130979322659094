<template>
  <div class="space-y-4">
    <offline-action-alert v-if="!isOnline" />
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
          <asom-form-field
            class="col-span-2"
            :label="'Inventory Group'"
            :state="inputStates('formData.inventoryGroup')"
            error="Inventory Group is required"
            required
          >
            <asom-input-select
              :state="inputStates('formData.inventoryGroup')"
              v-model="formData.inventoryGroup"
              :options="inventoryGroups"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            :label="'Item Type Name'"
            :state="inputStates('formData.inventoryTypeName')"
            error="Item Type is required"
            required
          >
            <asom-input-text
              v-model="formData.inventoryTypeName"
              :state="inputStates('formData.inventoryTypeName')"
            />
          </asom-form-field>

          <asom-form-field
            class="col-span-2"
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks need more than 10 characters"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Threshold Quantity"
            required
            :state="inputStates('formData.thresholdQuantity')"
            error="Threshold Quantity is required"
          >
            <asom-input-text
              type="number"
              v-model="formData.thresholdQuantity"
              :state="inputStates('formData.thresholdQuantity')"
            />
          </asom-form-field>
          <asom-form-field
            :label="'Require Secondary Location'"
            :state="inputStates('formData.requiredSecondaryLocation')"
            required
          >
            <asom-input-checkbox
              v-model="formData.requiredSecondaryLocation"
              label="Secondary Location Required"
              description="Check if Secondary Location is Required"
            />
          </asom-form-field>
          <asom-form-field
            label="Require Expire Date"
            required
            :state="inputStates('formData.requiredExpiredDate')"
          >
            <asom-input-checkbox
              v-model="formData.requiredExpiredDate"
              label="Expire Date Required"
              description="Check if Expire Date is Required"
            />
          </asom-form-field>
          <div class="col-span-4 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createinventoryTypeClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { required, minLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import { useVuelidate } from "@vuelidate/core";
import { createInventoryType } from "../../../../services/inventory.service";
import OfflineActionAlert from "@/layout/OfflineActionAlert"

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    OfflineActionAlert
  },
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        inventoryGroup: null,
        inventoryTypeName: null,
        thresholdQuantity: null,
        description: null,
        remarks: null,
        requiredExpiredDate: true,
        requiredSecondaryLocation: true,
      },
    };
  },
  validations() {
    return {
      formData: {
        inventoryGroup: { required },
        inventoryTypeName: { required },
        thresholdQuantity: { required },
        description: { required },
        remarks: { minLength: minLength(10) },
      },
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      stationName: "selectedStation/name",
      isOnline: "apiStatus/isInventoryModuleOnline",
    }),
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
    submissionData() {
      return {
        stationId: this.stationId,
        stationName: this.stationName,
        inventoryGroup: get(this.formData.inventoryGroup, "value"),
        inventoryGroupName: get(this.formData.inventoryGroup, "label"),
        inventoryTypeName: this.formData.inventoryTypeName,
        remarks: this.formData.remarks,
        requiredPrimaryLocation: true,
        requiredSecondaryLocation: this.formData.requiredSecondaryLocation,
        requiredExpiredDate: this.formData.requiredExpiredDate,
        thresholdQuantity: this.formData.thresholdQuantity,
        description: this.formData.description,
      }
    }
  },
  methods: {
    createinventoryTypeClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      if (this.isOnline) {
        this.isSubmitting = true;
        const result = await createInventoryType(this.submissionData);
        if (result.success) {
          this.isSubmitting = false;
          this.$router.go(-1);
        } else {
          this.isSubmitting = false;
          this.error = result.payload;
          this.$scrollTop();
        }
      } else {
        this.$store.dispatch('offlineData/createInventoryType', this.submissionData);
        this.$router.go(-1);
      }
    },
  },
};
</script>
